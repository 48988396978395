// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font-awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/css/all.css';

// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';

// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';

// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

// ckEditor
@import '../css/ckeditor.css';

// Customizações
@import '../css/app.css';
