.pointer{
   cursor:pointer;
}

.modal-open .select2-dropdown {
    z-index: 10060;
}

.select2-container .select2-selection--single {
    height: 37px !important;
}

.is-invalid .select2-selection,
.needs-validation ~ span > .select2-dropdown{
    border-color:red !important;
}

.modal-open .select2-close-mask {
    z-index: 10055;
}

.custom-table {
    min-width: 1000px;
}

.dz-message {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}
